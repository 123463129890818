<template>
  <div class="notice-list">
    <OurTable
      :headers="tableHeaders"
      :items="tableItems"
      :filters="filters"
      :table-fixed="false"
      class="notice-list-table"
      @clickRow="onClickRow"
    >
      <template #cell-image="{ value: image }">
        <div class="image-wrapper">
          <img alt="img" :src="image" @error="errorImageHandler" />
        </div>
      </template>

      <template #cell-description="{ value: description }">
        <div class="text-ellipsis">
          {{ description.description }}
        </div>
        <div class="location">
          {{ description.location }}
        </div>
      </template>

      <template #cell-price="{ value: price }">
        <OurMoney
          weight="regular"
          size="sm"
          :sum="price.sum"
          :currency-symbol="price.currencySymbol"
          :positive-sign="false"
          :negative-sign="false"
        />
      </template>

      <template #cell-priceGeneral="{ value: priceGeneral }">
        <OurMoney
          weight="regular"
          size="sm"
          :sum="priceGeneral.sum"
          :currency-symbol="priceGeneral.currencySymbol"
          :positive-sign="false"
          :negative-sign="false"
        />
      </template>

      <template #cell-status="{ value: status }">
        <OurTag size="md" :text="status.text" :color="status.color" />
      </template>

      <template #cell-modStatus="{ value: modStatus }">
        <OurTag size="md" :text="modStatus.text" :color="modStatus.color" />
      </template>
    </OurTable>

    <OurPagination
      v-if="isMoreThanOnePage"
      :current-page="paginationData.currentPage"
      :per-page="paginationData.perPage"
      :total="paginationData.total"
      @pageChange="onPageChange"
    />
  </div>
</template>

<script>
export default {
  name: "NoticeList",

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    filters: {
      type: Object,
      default: () => ({}),
    },

    isOwn: {
      type: Boolean,
      default: false,
    },

    paginationData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      moderationStatus: false,
    };
  },

  computed: {
    icons: () => ({
      Close: require("@/components/_bit/IconSvg/icons/Close.svg"),
    }),

    tableHeaders() {
      const data = [
        {
          value: "image",
          thClass: "w-24",
          tdClass: "max-w-[7rem] min-w-[5rem]",
        },
        {
          value: "name",
          text: this.$t("page.catalog.name"),
          thClass: this.nameClasses,
          tdClass: "name",
        },
        {
          value: "description",
          text: this.$t("page.cart.description"),
          thClass: this.descriptionClasses,
          tdClass: "description",
        },
        {
          value: "quantity",
          text: this.$t("page.fleaMarket.quantity"),
          thClass: "w-14 text-right",
          tdClass: "max-w-[3rem] min-w-[3rem] text-right",
        },
        {
          value: "price",
          text: this.$t("page.fleaMarket.pricePiece"),
          thClass: this.priceClasses,
          tdClass: "price",
        },
        {
          value: "priceGeneral",
          text: this.$t("page.fleaMarket.priceGeneral"),
          thClass: this.priceClasses,
          tdClass: "price",
        },
        {
          value: "status",
          text: this.$t("page.fleaMarket.status"),
          thClass: "w-[7.7rem]",
          tdClass: "max-w-[7.7rem] min-w-[7.7rem]",
        },
      ];

      if (this.moderationStatus) {
        data.push({
          value: "modStatus",
          text: this.$t("page.fleaMarket.moderationStatus"),
          thClass: "w-[7.7rem]",
          tdClass: "max-w-[7.7rem] min-w-[7.7rem]",
        });
      }

      return data;
    },

    tableItems() {
      return this.items.map((item) => {
        const {
          id,
          image,
          name,
          description,
          location,
          quantity,
          price,
          priceGeneral,
          status,
          modStatus,
        } = item;

        if (modStatus) {
          this.moderationStatus = true;
        }

        return {
          id,
          image,
          name,
          description: {
            description,
            location,
          },
          quantity,
          price,
          priceGeneral,
          status,
          modStatus,
          page: "NewNoticesDetails",
        };
      });
    },

    isMoreThanOnePage() {
      return this.paginationData.lastPage > 1;
    },

    nameClasses() {
      return this.isOwn ? "w-52" : "w-60";
    },

    priceClasses() {
      return this.isOwn ? "w-[6.8rem] text-right" : "w-[9rem] text-right";
    },

    descriptionClasses() {
      return this.isOwn ? "w-56" : "w-72";
    },
  },

  methods: {
    onPageChange(page) {
      this.$emit("pageChange", page);
    },

    errorImageHandler(event) {
      event.target.src = require("@/components/_bit/IconSvg/icons/Image.svg");
    },

    onClickRow(row) {
      const { id } = row;

      this.$router.push({ name: "NewNoticesDetails", params: { id } });
    },
  },
};
</script>

<style lang="postcss" scoped>
.notice-list {
  @apply h-full w-full;

  &-table:deep {
    thead {
      @apply whitespace-nowrap;
    }

    .image-wrapper {
      @apply p-1;
      @apply flex justify-center;
      @apply rounded border border-solid border-gray-100;
      @apply h-16 w-16;

      img {
        @apply object-contain;
      }
    }

    .name > div {
      @apply min-w-[10rem] max-w-[19.313rem];
      @apply whitespace-normal;
    }

    .description > div {
      @apply min-w-[10rem] max-w-[24.5rem];
      @apply whitespace-normal;
      @apply overflow-hidden;
    }

    .text-ellipsis {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .location {
      @apply mt-1;
      @apply text-xs text-gray-500;
    }

    .price {
      @apply w-16 text-right;

      .sum {
        @apply text-gray-900;

        .penny {
          @apply text-sm;
        }
      }
    }
  }
}
</style>
