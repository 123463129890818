<template>
  <OurCard class="order-card mb-4" border-radius="sm">
    <div class="order-card-wrapper" @click="onClick">
      <div class="flex w-full items-start justify-between">
        <OurTextField :label="$t('label.id')">
          <span>{{ application.id }}</span>
        </OurTextField>
        <OurTag
          v-if="application.status"
          class="status"
          size="md"
          :text="application.status.text"
          :color="application.status.color"
        />
      </div>
      <div class="grid grid-cols-2 gap-8">
        <OurTextField :label="$t('label.companyTitle')">
          <div class="flex flex-col">
            <span>{{ application.companyTitle }}</span>
          </div>
        </OurTextField>
        <OurTextField :label="$t('label.taxNumber')">
          <div class="flex flex-col">
            <span>{{ application.taxNumber }}</span>
          </div>
        </OurTextField>
        <OurTextField :label="$t('page.users.user')">
          <div class="flex flex-col">
            <span>{{ application.user.primaryRow }}</span>
          </div>
        </OurTextField>
        <OurTextField :label="$t('page.users.role')" :value="application.user.secondaryRow" />
        <OurTextField :label="$t('page.users.contacts')">
          <div class="flex flex-col">
            <span v-if="application.contacts?.phone">{{ application.contacts?.phone }}</span>
            <span v-if="application.contacts?.email" class="break-words underline">{{
              application.contacts?.email
            }}</span>
          </div>
        </OurTextField>
        <OurTextField
          class="text-ellips"
          :label="$t('page.orders.comment')"
          :value="application.comment ? application.comment : '–'"
        />
      </div>
    </div>
  </OurCard>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ApplicationsCard",
  props: {
    application: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters("user", ["getPermission"]),

    icons: () => ({
      more: require("@material-symbols/svg-400/outlined/more_horiz-fill.svg"),
    }),
  },

  methods: {
    onClick() {
      this.$emit("clickOnOrder");
    },
  },
};
</script>

<style scoped lang="postcss">
.order-card {
  .order-card-wrapper:deep {
    @apply flex flex-col items-start space-y-4;

    .status {
      @apply w-auto;
    }
  }
}
</style>
