<template>
  <OurPage :back-route="backRoute" class="access-groups" :title="$t('title.news.add')" width="md">
    <NewsForm
      ref="newsForm"
      v-model="form"
      :upload-files="uploadFiles"
      :type="imageType"
      @uploadFiles="uploadFilesMutation"
      @deleteFiles="deleteFileMutation"
    />

    <template #footer-left>
      <OurButton class="button" :text="$t('button.save')" @click="onClickSaveButton" />
      <OurLink :route="backRoute">
        <OurButton class="button" :text="$t('button.back')" variant="secondary" />
      </OurLink>
    </template>
  </OurPage>
</template>

<script>
import {
  deleteFileMutation,
  uploadFilesMutation,
} from "@/views/settings/base-knowledge/chapter/add/gql/mutations";
import apolloMixin from "@/mixins/apollo.mixin";
import NewsForm from "@/views/settings/news/add/_components/NewsForm.vue";
import { createNewsMutation } from "@/views/settings/news/add/gql/mutations";

export default {
  name: "ChapterAdd",
  components: { NewsForm },
  mixins: [apolloMixin],
  data() {
    return {
      backRoute: {
        name: "SettingsNews",
        title: this.$t("title.news._"),
      },
      form: {
        name: "",
        description: "",
        content: "",
        previewImage: {},
        mainImage: null,
        banner: null,
        isActive: true,
        bannerIsActive: false,
        publishedAt: "",
      },
      uploadFiles: [],
      allFiles: [],
      imageType: "",
    };
  },
  methods: {
    async uploadFilesMutation(object) {
      const { uploadFiles: files } = await this.$post(
        uploadFilesMutation,
        { files: object.selectedFiles, collection: object.type },
        { withLoader: false, withNotify: false }
      );

      this.uploadFiles = files;
      this.imageType = object.type;
    },

    async deleteFileMutation(id) {
      await this.$post(deleteFileMutation, { id }, { withLoader: false, withNotify: false });
    },

    async onClickSaveButton() {
      this.$refs.newsForm.emitForm();
      if (this.form.mainImage) this.allFiles.push(this.form.mainImage);
      if (this.form.banner) this.allFiles.push(this.form.banner);
      this.allFiles.push(this.form.previewImage);

      if (this.form.isValid) {
        this.form.files = { sync: this.allFiles };
        const { createNews } = await this.$post(
          createNewsMutation,
          { ...this.form },
          {
            delaySuccessNotify: true,
          }
        );

        if (createNews.id) await this.$router.push({ name: "SettingsNews" });
      }
    },
  },
};
</script>
