import gql from "graphql-tag";

export const dashboardDataQuery = gql`
  query dashboardData(
    $filters: Filter
    $page: Int!
    $perPage: Int
    $mutualPerPage: Int!
    $settlementsFilters: SettlementsFilters
  ) {
    productCategories {
      id
      description
      parent
      children
    }

    orders(
      orderBy: { column: "documentDate", direction: desc }
      filters: $filters
      page: $page
      first: $perPage
    ) {
      data {
        id
        number
        contract {
          name
          currency {
            code
          }
        }
        amount
        status
        payStatus
        name
        documentDate
      }
    }

    mutualSettlements(filters: $settlementsFilters, page: $page, perPage: $mutualPerPage) {
      mutualSettlements {
        id
        type
        documentDate
        amount
        operation
        currency {
          code
        }
      }
    }

    currencies {
      code
      exchangeRate
      rateDate
      crossRates
    }
  }
`;

export const contractsQuery = gql`
  query contracts($companyId: ID) {
    contracts(companyId: $companyId) {
      id
      name
      currency {
        symbol
      }
      balanceAmount
    }
  }
`;
