import gql from "graphql-tag";

export const createNewsMutation = gql`
  mutation createNews(
    $name: String!
    $isActive: Boolean
    $description: String
    $content: String
    $files: SyncAssociations
    $showBanner: Boolean
    $publishedAt: Timestamp!
  ) {
    createNews(
      name: $name
      description: $description
      content: $content
      isActive: $isActive
      files: $files
      showBanner: $showBanner
      publishedAt: $publishedAt
    ) {
      id
    }
  }
`;
