import i18n from "@/plugins/vue-i18n";

import News from "@/views/settings/news/index/index.vue";
import NewsAdd from "@/views/settings/news/add/index.vue";
import NewsEdit from "@/views/settings/news/edit";

const news = [
  {
    path: "settings/settings-news",
    name: "SettingsNews",
    component: News,
    meta: {
      title: i18n.t("title.news._"),
    },
  },
  {
    path: "settings/settings-news/add",
    name: "NewsAdd",
    component: NewsAdd,
    meta: {
      title: i18n.t("title.news.add"),
    },
  },
  {
    path: "settings/settings-news/:id/edit",
    name: "NewsEdit",
    component: NewsEdit,
    meta: {
      title: i18n.t("title.news.edit"),
    },
  },
];

export default news;
