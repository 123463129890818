import i18n from "@/plugins/vue-i18n";

import NewApplications from "../../../../views/settings/new-applications/index/index.vue";
import NewApplicationsDetails from "../../../../views/settings/new-applications/details";

const newApplications = [
  {
    path: "settings/new-applications",
    name: "NewApplications",
    component: NewApplications,
    meta: {
      title: i18n.t("title.newApplications._"),
    },
  },
  {
    path: "settings/new-applications/:id/details",
    name: "NewApplicationsDetails",
    component: NewApplicationsDetails,
    meta: {
      title: i18n.t("title.newApplications.details"),
    },
  },
];

export default newApplications;
