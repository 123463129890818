import i18n from "@/plugins/vue-i18n";

const Login = () => import(/* webpackChunkName: "auth" */ "@/views/auth/login");
const Registration = () => import("@/views/registr");

const login = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: i18n.t("title.login"),
    },
  },
  {
    path: "/admin/login",
    name: "AdminLogin",
    component: Login,
    meta: {
      title: i18n.t("title.login"),
    },
  },
  {
    path: "/registration",
    name: "Registration",
    component: Registration,
    meta: {
      title: i18n.t("title.registration"),
    },
  },
];

export default login;
