import DateServiceDefault from "@/services/_bit/DateService";
import { getTime } from "date-fns";

class DateService extends DateServiceDefault {
  /**
   * Get the date in timestamp format
   * @returns string
   */
  getDateTimestamp(date) {
    const timestampInSeconds = getTime(new Date(date)) / 1000;

    return date ? timestampInSeconds : "";
  }
}

export default new DateService();
