<script>
import NewsList from "@/views/settings/news/index/_components/NewsList.vue";
import CustomersList from "@/views/settings/customers/index/_components/CustomersList.vue";
import apolloMixin from "@/mixins/apollo.mixin";
import { newsListQuery } from "@/views/settings/news/index/gql/queries";
import { mapGetters } from "vuex";
import DateService from "@/services/date.service";
import i18n from "@/plugins/vue-i18n";

export default {
  name: "Index",
  components: { CustomersList, NewsList },
  mixins: [apolloMixin],
  data() {
    return {
      newsListData: [],
      paginationData: {},
    };
  },
  computed: {
    ...mapGetters("user", ["getPermission"]),

    addRoute() {
      return { name: "NewsAdd" };
    },
  },
  created() {
    this.getData();
  },
  methods: {
    async getData(page) {
      if (page && page === this.paginationData.currentPage) return;

      const { newsList } = await this.$get(newsListQuery, {
        page,
      });

      if (newsList) {
        const listData = newsList.data.map((newsListItem) => {
          const { id, name, description, isActive, publishedAt } = newsListItem;
          const status = this.getStatus(isActive);

          return {
            id,
            name,
            description,
            status,
            publishedAt: DateService.dateConverter(publishedAt),
            page: "NewsEdit",
          };
        });

        this.paginationData = newsList.paginatorInfo;
        this.newsListData = listData;
      }
    },

    onClickRow(id) {
      this.$router.push({ name: "NewsEdit", params: { id } });
    },

    getStatus(status) {
      if (status) {
        return {
          text: i18n.t("news.status.published"),
          color: "green",
        };
      } else {
        return {
          text: i18n.t("news.status.notPublished"),
          color: "red",
        };
      }
    },
  },
};
</script>

<template>
  <OurPage :title="$t('title.news._')">
    <NewsList
      :items="newsListData"
      :pagination-data="paginationData"
      @pageChange="getData"
      @onClickRow="onClickRow"
    />
    <template #header-right>
      <OurLink v-if="getPermission['news.create']" :route="addRoute">
        <OurButton :text="$t('button.add')" />
      </OurLink>
    </template>
  </OurPage>
</template>
