import ApiService from "@/services/api.service";

async function getCompanies(query) {
  let resource = "/companies/all";

  if (query) resource += `?filter[assigned_customer]=${query}`;

  const response = await ApiService.get(resource);

  return response.data;
}

export { getCompanies };
