<template>
  <OurCard class="order-card mb-4" border-radius="sm">
    <div class="order-card-wrapper" @click="onClick">
      <div v-if="item.status" class="flex w-full items-center justify-between">
        <OurTag class="status" size="md" :text="item.status.text" :color="item.status.color" />
        <div class="text-sm text-gray-500">{{ item.publishedAt }}</div>
      </div>
      <OurTextField v-if="item.name" :label="$t('page.customers.title')" :value="item.name" />
    </div>
  </OurCard>
</template>

<script>
export default {
  name: "NewsCard",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    onClick() {
      this.$emit("clickOnOrder");
    },
  },
};
</script>

<style scoped lang="postcss">
.order-card {
  .order-card-wrapper:deep {
    @apply flex flex-col items-start space-y-4;

    .status {
      @apply w-auto;
    }
  }
}
</style>
