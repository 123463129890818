<template>
  <OurCard class="order-card mb-4" border-radius="sm">
    <div class="order-card-wrapper" @click="onClick">
      <div class="flex w-full items-start justify-between">
        <OurTag v-if="status" class="status" size="md" :text="status.text" :color="status.color" />
        <div class="flex flex-col items-end">
          <div class="text-sm text-gray-500">{{ `#${id}` }}</div>
          <div
            v-if="getPermission['dealer.impersonate']"
            class="relative flex h-8 w-8 items-center justify-center"
            @click.stop="onClickHelperMenu(id)"
          >
            <OurSvgIcon :src="icons.more" />

            <div v-if="isShownMenuBlock === id" class="menu-block">
              <a href="" @click.prevent="loginImpersonateHandler(id)">
                {{ $t("page.users.enter") }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <OurTextField :label="$t('page.users.user')">
        <div class="flex flex-col">
          <span>{{ user.primaryRow }}</span>
          <span class="text-xs text-gray-500">{{ visit }}</span>
        </div>
      </OurTextField>
      <OurTextField :label="$t('page.users.role')" :value="user.secondaryRow" />
      <OurTextField :label="$t('page.users.contacts')">
        <div class="flex flex-col">
          <span v-if="contacts?.phone">{{ contacts?.phone }}</span>
          <span v-if="contacts?.email" class="underline">{{ contacts?.email }}</span>
        </div>
      </OurTextField>
      <OurTextField v-if="customer" :label="$t('page.users.customer')" :value="customer" />
    </div>
  </OurCard>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UsersCard",
  props: {
    status: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: () => {},
    },
    contacts: {
      type: Object,
      default: () => {},
    },
    customer: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    visit: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isShownMenuBlock: null,
    };
  },

  computed: {
    ...mapGetters("user", ["getPermission"]),

    icons: () => ({
      more: require("@material-symbols/svg-400/outlined/more_horiz-fill.svg"),
    }),
  },

  created() {
    window.addEventListener("click", (event) => this.closeMenuBlock(event));
  },

  destroyed() {
    window.removeEventListener("click", (event) => this.closeMenuBlock(event));
  },

  methods: {
    ...mapActions(["loginImpersonate"]),

    async loginImpersonateHandler(val) {
      await this.$router.push({ name: "Dashboard" });
      await this.loginImpersonate(val);
      this.$router.go();
    },

    onClick() {
      this.$emit("clickOnOrder");
    },
    closeMenuBlock(event) {
      if (!this.$el.contains(event.target)) {
        this.isShownMenuBlock = false;
      }
    },
    onClickHelperMenu(val) {
      if (this.isShownMenuBlock === val) {
        this.isShownMenuBlock = null;

        return;
      }

      this.isShownMenuBlock = val;
    },
  },
};
</script>

<style scoped lang="postcss">
.order-card {
  .order-card-wrapper:deep {
    @apply flex flex-col items-start space-y-4;

    .status {
      @apply w-auto;
    }
  }
}
.menu-block {
  @apply absolute right-0 top-6;
  @apply h-auto;
  @apply rounded-lg border border-gray-700 bg-gray-900 text-white;
  @apply py-1;
  @applu flex flex-col;
  a {
    @apply block whitespace-nowrap px-3 py-2;
    &:hover {
      @apply bg-gray-800;
    }
  }
}
</style>
