import gql from "graphql-tag";

export const newsListQuery = gql`
  query newsList($page: Int, $perPage: Int) {
    newsList(orderBy: { column: "publishedAt", direction: desc }, page: $page, first: $perPage) {
      data {
        id
        name
        isActive
        publishedAt
      }
      paginatorInfo {
        currentPage
        perPage
        total
        lastPage
      }
    }
  }
`;
