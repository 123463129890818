<template>
  <t-dropdown
    :show="isShownOptions"
    :classes="dropdownClasses"
    toggle-on-focus
    :data-cy="dataCy"
    class="dropdown"
    @click="hideOptions"
  >
    <div slot="trigger" class="relative" :class="iconRotateClass">
      <OurButton
        ref="dropdown"
        :text="text"
        :size="size"
        :variant="variant"
        :filled="filled"
        :color="color"
        :data-cy="`${dataCy}-button`"
        @mousedown.native="onMousedown"
      >
        <template #right>
          <OurSvgIcon
            v-if="dropdownIcon"
            :src="icons.AngleDown"
            color="white"
            class="icon"
            :size="iconSize"
            :class="iconClasses"
            :data-cy="`${dataCy}-caret`"
          />
        </template>
      </OurButton>
    </div>

    <div v-if="!closed" class="dropdown-list">
      <!-- @slot Use it to add dropdown list. -->
      <slot>
        <OurDropdownList
          v-model="selectValue"
          :options="options"
          :value-key="valueKey"
          :item-label="itemLabel"
        />
      </slot>
    </div>
  </t-dropdown>
</template>

<script>
import TDropdown from "vue-tailwind/dist/t-dropdown";
import OurSvgIcon from "@/components/_bit/IconSvg";
import OurButton from "@/components/_bit/Button";
import OurDropdownList from "@/components/_bit/DropdownList";

export default {
  name: "OurDropdownButton",

  components: {
    TDropdown,
    OurSvgIcon,
    OurButton,
    OurDropdownList,
  },

  props: {
    /**
     * Set button text.
     */
    text: {
      type: String,
      default: "",
    },

    /**
     * The variant of the button.
     * @values primary, secondary, thirdary
     */
    variant: {
      type: String,
      default: "primary",
    },

    /**
     * Fill the background for thirdary variant.
     */
    filled: {
      type: Boolean,
      default: false,
    },

    /**
     * The color of the button.
     * @values gray, red, orange, yellow, green, blue, violet, fuchsia
     */
    color: {
      type: String,
      default: "",
    },

    /**
     * The size of the button.
     * @values xs, sm, md, lg
     */
    size: {
      type: String,
      default: "md",
    },

    /**
     * Make the dropdown icon visible.
     */
    dropdownIcon: {
      type: Boolean,
      default: true,
    },

    /**
     * Set select value.
     */
    value: {
      type: [String, Number],
      default: "",
    },

    /**
     * Set options for component.
     */
    options: {
      type: Array,
      default: () => [],
    },

    /**
     * Label from option Object, that will be visible in the dropdown.
     */
    itemLabel: {
      type: String,
      default: "label",
    },

    /**
     * Set track value key.
     */
    valueKey: {
      type: String,
      default: "id",
    },

    /**
     * The position of dropdown list on the y-axis.
     * @values top, bottom
     */
    listYPosition: {
      type: String,
      default: "bottom",
    },

    /**
     * The position of dropdown list on the x-axis.
     * @values left, right
     */
    listXPosition: {
      type: String,
      default: "left",
    },

    /**
     * Sets data-cy attribute for correct element sampling in tests.
     */
    dataCy: {
      type: String,
      default: "",
    },

    closed: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isShownOptions: false,
  }),

  computed: {
    selectValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.isShownOptions = false;
        this.$emit("input", value);
      },
    },

    icons: () => ({
      AngleDown: require("@/components/_bit/IconSvg/icons/Angle-down.svg"),
    }),

    dropdownListYPosition() {
      return this.listYPosition === "bottom" ? "top-0" : `dropdown-list-bottom-${this.size}`;
    },

    dropdownListXPosition() {
      return this.listXPosition === "right" ? "right-0" : "left-0";
    },

    iconRotateClass() {
      return this.isShownOptions ? "icon-rotate" : "";
    },

    iconClasses() {
      const iconVariant = `${this.variant}-icon`;

      return [iconVariant];
    },

    iconSize() {
      const sizes = {
        xs: "xs",
        sm: "xs",
        md: "sm",
        lg: "md",
      };

      return sizes[this.size];
    },

    dropdownClasses() {
      return {
        button: `block px-4 py-2 text-white transition duration-100 ease-in-out bg-blue-500 border
          border-transparent rounded shadow-sm hover:bg-blue-600 focus:border-blue-500 focus:ring-2
          focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50
          disabled:cursor-not-allowed`,
        wrapper: "inline-flex flex-col",
        dropdownWrapper: "relative z-10 mb-0 dropdown-list",
        dropdown: `${this.dropdownListYPosition} ${this.dropdownListXPosition} absolute
        rounded-lg shadow bg-white mt-1 dropdown-block`,
        enterClass: "opacity-0 scale-95",
        enterActiveClass: "transition transform ease-out duration-100",
        enterToClass: "opacity-100 scale-100",
        leaveClass: "opacity-100 scale-100",
        leaveActiveClass: "transition transform ease-in duration-75",
        leaveToClass: "opacity-0 scale-95",
      };
    },
  },

  created() {
    window.addEventListener("click", (event) => this.closeOptions(event));
  },

  destroyed() {
    window.removeEventListener("click", (event) => this.closeOptions(event));
  },

  methods: {
    onMousedown() {
      this.isShownOptions = !this.isShownOptions;
      this.$emit("closeDropdown", !this.isShownOptions);
    },

    closeOptions(event) {
      if (!this.$el.contains(event.target)) {
        this.isShownOptions = false;
      }
    },

    hideOptions() {
      this.isShownOptions = false;
    },
  },
};
</script>

<style lang="postcss" scoped>
.dropdown {
  scrollbar-color: #888 transparent;
  .button {
    @apply text-sm sm:text-base;
  }
  .icon {
    @apply transition-all duration-300;
  }

  .icon-rotate {
    .icon {
      @apply rotate-180 transform;
    }
  }

  .dropdown-list {
    @apply max-h-60 w-auto overflow-y-auto overflow-x-hidden;
    @apply border-gray-300;
    @apply top-3;
  }

  :deep(.dropdown-list-bottom) {
    &-xs {
      @apply bottom-[1.625rem];
    }

    &-sm {
      @apply bottom-10;
    }

    &-md {
      @apply bottom-12;
    }

    &-lg {
      @apply bottom-[3.75rem];
    }
  }
}

.primary-icon:deep(g [fill]) {
  @apply fill-current text-white;
}

.secondary-icon:deep(g [fill]) {
  @apply fill-current text-gray-900;
}

.thirdary-icon:deep(g [fill]) {
  @apply fill-current text-gray-900;
}
</style>
