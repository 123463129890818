import gql from "graphql-tag";

export const createNotificationEmailMutation = gql`
  mutation createNotificationEmail($group: NotificationEmailGroup!, $email: String!) {
    createNotificationEmail(group: $group, email: $email) {
      id
    }
  }
`;

export const updateNotificationEmailMutation = gql`
  mutation updateNotificationEmail($group: NotificationEmailGroup!, $id: ID!, $email: String!) {
    updateNotificationEmail(group: $group, id: $id, email: $email) {
      id
    }
  }
`;

export const deleteNotificationEmailMutation = gql`
  mutation deleteNotificationEmail($id: ID!) {
    deleteNotificationEmail(id: $id) {
      id
    }
  }
`;
