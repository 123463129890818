import gql from "graphql-tag";

export const updateDealerMutation = gql`
  mutation updateDealer(
    $userId: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $roleId: ID
    $customerId: ID
    $comment: String
    $isActive: Boolean
  ) {
    updateDealer(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      roleId: $roleId
      customerId: $customerId
      comment: $comment
      isActive: $isActive
    ) {
      id
    }
  }
`;
