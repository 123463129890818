import privacy from "./privacy";
import agreement from "./agreement";

const StaticLayout = () => import(/* webpackChunkName: "auth" */ "@/layouts/_bit/static");

const staticLayout = [
  {
    path: "/",
    name: "StaticLayout",
    redirect: { name: "Login" },
    component: StaticLayout,
    children: [...privacy, ...agreement],
  },
];

export default staticLayout;
