export default class JwtServiceDefault {
  #IMPERSONATE_TOKEN = "impersonate-token";
  #IMPERSONATE_TOKEN_EXPIRES = "impersonate-token-expires";
  #AUTH_TOKEN = "token";
  #AUTH_TOKEN_EXPIRES = "token-expires";
  #VERSION_HASH = "version-hash";

  getAuthToken() {
    return window.localStorage.getItem(this.#AUTH_TOKEN);
  }

  getAuthTokenExpiresTime() {
    return window.localStorage.getItem(this.#AUTH_TOKEN_EXPIRES);
  }

  saveAuthToken(token, expires) {
    window.localStorage.setItem(this.#AUTH_TOKEN, token);
    window.localStorage.setItem(this.#AUTH_TOKEN_EXPIRES, expires);
  }

  destroyAuthToken() {
    window.localStorage.removeItem(this.#AUTH_TOKEN);
    window.localStorage.removeItem(this.#AUTH_TOKEN_EXPIRES);
  }

  getVersionHash() {
    return window.localStorage.getItem(this.#VERSION_HASH);
  }

  getImpersonateToken() {
    return window.localStorage.getItem(this.#IMPERSONATE_TOKEN);
  }

  getImpersonateTokenExpiresTime() {
    return window.localStorage.getItem(this.#IMPERSONATE_TOKEN_EXPIRES);
  }

  saveImpersonateToken(token, expires) {
    window.localStorage.setItem(this.#IMPERSONATE_TOKEN, token);
    window.localStorage.setItem(this.#IMPERSONATE_TOKEN_EXPIRES, expires);
  }

  destroyImpersonateToken() {
    window.localStorage.removeItem(this.#IMPERSONATE_TOKEN);
    window.localStorage.removeItem(this.#IMPERSONATE_TOKEN_EXPIRES);
  }
}
