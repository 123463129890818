import i18n from "@/plugins/vue-i18n";

const PrivacyPolicy = () =>
  import(/* webpackChunkName: "auth" */ "@/views/privacy-policy/index.vue");

const privacy = [
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: {
      title: i18n.t("title.privacyPolicy"),
    },
  },
];

export default privacy;
