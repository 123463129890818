<template>
  <div class="form">
    <OurGroup class="form-group">
      <OurInput
        v-model="form.companyName"
        :label="$t('label.companyTitle')"
        :placeholder="$t('placeholder.title')"
      />

      <OurInput
        v-model="form.taxNumber"
        :label="$t('label.taxNumber')"
        :placeholder="$t('placeholder.taxNumber')"
        :error="taxNumberError"
      />

      <OurInput
        v-model="form.id"
        :label="$t('label.id')"
        :placeholder="$t('placeholder.caseNumber')"
      />

      <OurSelect
        v-model="form.status"
        :label="$t('label.status')"
        :placeholder="$t('placeholder.selectStatus')"
        :options="getStatuses()"
        item-label="text"
        value-key="value"
      />
    </OurGroup>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ValidationService from "@/services/validation.service";
import { maxLength, numeric, minLength } from "vuelidate/lib/validators";

const { TAX_NUMBER_MAX_LENGTH, TAX_NUMBER_MIN_LENGTH } = ValidationService;

export default {
  name: "FilterForm",

  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      form: {
        companyName: "",
        taxNumber: "",
        id: "",
        status: "",
      },
    };
  },

  validations: {
    form: {
      taxNumber: {
        numeric,
        maxLength: maxLength(TAX_NUMBER_MAX_LENGTH),
        minLength: minLength(TAX_NUMBER_MIN_LENGTH),
      },
    },
  },

  computed: {
    ...mapGetters("newApplications", ["getStatuses"]),

    taxNumberError() {
      const isDirty = this.$v.form.taxNumber.$dirty;
      const isFilled = this.$v.form.taxNumber.required;
      const numeric = this.$v.form.taxNumber.numeric;
      const maxLength = this.$v.form.taxNumber.maxLength;
      const minLength = this.$v.form.taxNumber.minLength;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      } else if (!numeric) {
        error = this.$t("validation.taxNumber");
      } else if (isDirty && !minLength) {
        error = this.$t("validation.textMinLength", {
          length: TAX_NUMBER_MIN_LENGTH,
        });
      } else if (isDirty && !maxLength) {
        error = this.$t("validation.textMaxLength", {
          length: TAX_NUMBER_MAX_LENGTH,
        });
      }

      return error;
    },
  },

  watch: {
    value: {
      handler: "setForm",
      deep: true,
      immediate: true,
    },

    form: {
      handler: "onChangeForm",
      deep: true,
    },
  },

  methods: {
    setForm() {
      this.form = this.value;
    },

    onChangeForm() {
      this.$emit("input", this.form);
    },
  },
};
</script>
