import ApiServiceDefault from "@/services/_bit/ApiService";
import NotifyServiceDefault from "@/services/_bit/NotifyService";
import store from "@/store";
import router from "@/router";

class ApiService extends ApiServiceDefault {
  #notify = new NotifyServiceDefault();

  /**
   * Set axios interceptors
   * @param { Object } $axios
   */
  setAxiosInterceptors($axios) {
    $axios.interceptors.response.use(
      (response) => {
        this.loader("off", response.config.url);

        const { headers } = response;

        if (headers) {
          const token = headers["Refresh-Token"];
          const expires = headers["Refresh-Expires"];

          if (token) {
            store.commit("SET_AUTH", { token, expires });
          }
        }

        return response;
      },
      (error) => {
        const message = error.response?.data?.message;
        const status = error.response?.status;
        const routeName = router.history.current.name;
        const isLoginPage = routeName === "Login" || routeName === "AdminLogin";

        this.loader("off", error.config.url);

        if (status === 401 && !isLoginPage) {
          store.dispatch("clearAuth");
          store.dispatch("clearImpersonateAuth");
        } else if (Array.isArray(message) && message.length) {
          message.forEach((code) => this.#notify.error(code));
        } else {
          this.#notify.error(message);
        }

        return Promise.reject(error);
      }
    );
  }

  /**
   * Change loaders state (on | off)
   * @param { String } state
   * @param { String } resource
   */
  loader(state, resource) {
    if (state === "on") store.commit("loaderTop/SET_LOADING_ON", resource);
    if (state === "off") store.commit("loaderTop/SET_LOADING_OFF", resource);
  }
}

export default new ApiService();
