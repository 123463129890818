import ApiService from "@/services/api.service";
import JwtService from "@/services/jwt.service";
import { addHours, getUnixTime } from "date-fns";
import router from "@/router";
import isUserDealer from "./user.module";

import {
  login,
  loginByToken,
  refreshAuth,
  resetPassword,
  changeUserPassword,
  logout,
  loginImpersonate,
} from "@/api/auth";

export default {
  state: {
    token: JwtService.getAuthToken(),
    expires: JwtService.getAuthTokenExpiresTime(),
    impersonateToken: JwtService.getImpersonateToken(),
    impersonateTokenExpires: JwtService.getImpersonateTokenExpiresTime(),
  },

  getters: {
    isAuthenticatedUser(state) {
      return !!state.token;
    },

    isAuthenticatedImpersonateUser(state) {
      return !!state.impersonateToken;
    },

    tokenValidityTime(state) {
      return Number(state.expires);
    },

    tokenImpersonateValidityTime(state) {
      return Number(state.impersonateTokenExpires);
    },
  },

  mutations: {
    async SET_AUTH(state, { token, expires }) {
      state.token = token;

      if (!expires) {
        const now = Date.now();
        const newDate = addHours(now, 1);

        state.expires = getUnixTime(newDate);
      } else {
        state.expires = expires;
      }

      JwtService.saveAuthToken(state.token, state.expires);
      // JwtService.saveVersionHash(userAuthData.version_hash);

      ApiService.setHeader();
    },

    async SET_AUTH_IMPERSONATE(state, { token, expires }) {
      state.impersonateToken = token;

      if (!expires) {
        const now = Date.now();
        const newDate = addHours(now, 1);

        state.impersonateTokenExpires = getUnixTime(newDate);
      } else {
        state.impersonateTokenExpires = expires;
      }

      window.localStorage.setItem("isUserDealer", "1");

      JwtService.saveImpersonateToken(state.impersonateToken, state.impersonateTokenExpires);

      ApiService.setHeader();
    },

    PURGE_AUTH(state) {
      state.token = null;
      state.expires = null;
    },

    PURGE_AUTH_IMPERSONATE(state) {
      state.impersonateToken = null;
      state.impersonateTokenExpires = null;
    },
  },

  actions: {
    async login(context, userData) {
      const response = await login(userData);

      context.commit("SET_AUTH", response);
    },

    async loginImpersonate(context, id) {
      const response = await loginImpersonate(id);

      context.commit("SET_AUTH_IMPERSONATE", response);
    },

    async loginByToken(context, token) {
      const response = await loginByToken(token);

      context.commit("SET_AUTH", response);
    },

    async refreshAuth(context) {
      const response = await refreshAuth();

      context.commit("SET_AUTH", response);
    },

    async resetPassword(context, userData) {
      await resetPassword(userData);
    },

    async changeUserPassword(context, userData) {
      const response = await changeUserPassword(userData);

      context.commit("SET_AUTH", response);
    },

    async verifyAuth(context) {
      const { isAuthenticatedUser, tokenValidityTime } = context.getters;
      const now = Math.floor(Date.now() / 1000);

      if (isAuthenticatedUser) {
        ApiService.setHeader();

        if (now > tokenValidityTime) {
          await context.dispatch("refreshAuth");
        }
      } else {
        await context.dispatch("clearAuth");
      }
    },

    async verifyImpersonateAuth(context) {
      const { isAuthenticatedImpersonateUser, tokenImpersonateValidityTime } = context.getters;
      const now = Math.floor(Date.now() / 1000);

      if (isAuthenticatedImpersonateUser) {
        ApiService.setHeader();

        if (now > tokenImpersonateValidityTime) {
          await context.dispatch("clearImpersonateAuth");
        }
      } else {
        await context.dispatch("clearImpersonateAuth");
      }
    },

    clearAuth(context) {
      context.commit("PURGE_AUTH");

      JwtService.destroyAuthToken();

      if (!isUserDealer.state.user) {
        router.push({ name: "Login" });
      } else {
        if (isUserDealer.state.user.type !== "dealer") {
          router.push({ path: "/admin/login" });
        } else {
          router.push({ name: "Login" });
        }
      }
    },

    clearImpersonateAuth(context) {
      context.commit("PURGE_AUTH_IMPERSONATE");

      JwtService.destroyImpersonateToken();
    },

    async logout(context) {
      await logout();

      window.localStorage.removeItem("permissions");

      context.dispatch("clearAuth");
      context.dispatch("clearImpersonateAuth");
    },

    async logoutImpersonate(context) {
      await logout();

      window.localStorage.setItem("isUserDealer", "0");

      context.dispatch("clearImpersonateAuth");
    },
  },
};
