import i18n from "@/plugins/vue-i18n";

const UserAgreement = () =>
  import(/* webpackChunkName: "auth" */ "@/views/user-agreement/index.vue");

const agreement = [
  {
    path: "/user-agreement",
    name: "UserAgreement",
    component: UserAgreement,
    meta: {
      title: i18n.t("title.userAgreement"),
    },
  },
];

export default agreement;
