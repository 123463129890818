<template>
  <OurModal
    v-model="isShownModal"
    :title="$t('title.fleaMarket.settingsModal')"
    :description="$t('newApplications.modalDesc')"
  >
    <SettingsForm
      ref="emailForm"
      v-model="form"
      @create="createNotice"
      @update="updateNotice"
      @delete="deleteNotice"
    />

    <template #footer-left>
      <OurButton :text="$t('button.close')" @click="closeModal" />
    </template>
  </OurModal>
</template>

<script>
import SettingsForm from "./components/SettingsForm.vue";
import apolloMixin from "@/mixins/apollo.mixin";
import { notificationEmailsQuery } from "@/views/settings/new-notices/settings/gql/queries";
import {
  createNotificationEmailMutation,
  deleteNotificationEmailMutation,
  updateNotificationEmailMutation,
} from "@/views/settings/new-notices/settings/gql/mutations";

export default {
  name: "SettingsModal",
  components: {
    SettingsForm,
  },
  mixins: [apolloMixin],

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        emails: [],
      },
    };
  },

  computed: {
    isShownModal: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },

  watch: {
    isShownModal: "onChangeShownModal",
  },

  created() {
    this.getData();
  },

  methods: {
    async getData() {
      const { notificationEmails } = await this.$get(notificationEmailsQuery, {
        group: "notice",
      });

      this.form.emails = notificationEmails;
    },

    async createNotice(email) {
      this.$refs.emailForm.emitForm();

      if (this.form.isValid) {
        const { createNotificationEmail } = await this.$post(
          createNotificationEmailMutation,
          { group: "notice", email: email },
          {
            delaySuccessNotify: false,
          }
        );

        if (createNotificationEmail.id) await this.getData();
      }
    },

    async updateNotice(object) {
      this.$refs.emailForm.emitForm();

      if (this.form.isValid) {
        const { updateNotificationEmail } = await this.$post(
          updateNotificationEmailMutation,
          { group: "notice", id: object.id, email: object.email },
          {
            delaySuccessNotify: false,
          }
        );

        if (updateNotificationEmail.id) await this.getData();
      }
    },

    async deleteNotice(id) {
      const { deleteNotificationEmail } = await this.$post(
        deleteNotificationEmailMutation,
        { id: id },
        {
          delaySuccessNotify: false,
        }
      );

      if (deleteNotificationEmail.id) await this.getData();
    },

    onChangeShownModal() {
      if (!this.isShownModal) {
        this.cleanForm();
      }
    },

    cleanForm() {
      this.form.emails = this.form.emails.filter((el) => el.id);
      this.getData();
    },

    closeModal() {
      this.isShownModal = false;
      this.cleanForm();
    },
  },
};
</script>
