import Vue from "vue";
import App from "./App.vue";
import "/src/assets/css/index.pcss";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./plugins/vuelidate";
import "./plugins/vue-tailwind";
import i18n from "@/plugins/vue-i18n";
import ApiService from "@/services/api.service";
import "./mixins/global.mixin";
import "./components/_bit";

// Markdown
import "github-markdown-css/github-markdown.css";
import "highlight.js/styles/github.css";

import hljs from "highlight.js";
import markdown from "markdown-it";
import markdownHighlight from "markdown-it-highlightjs";

const md = markdown({ breaks: true, html: true });

Vue.filter("markdown", function (value) {
  if (!value) return "";

  md.use(markdownHighlight, { hljs });

  return md.render(value);
});

import Vue2Editor from "vue2-editor";

Vue.use(Vue2Editor);

// Vue apollo
import VueApollo from "vue-apollo";
import apolloClient from "@/plugins/apollo-vue";

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

ApiService.axiosInit();

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
