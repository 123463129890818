<template>
  <OurGroups>
    <OurGroup :title="$t('newApplications.companyDetails')">
      <OurInput
        v-model="form.companyName"
        :label="$t('label.companyTitle')"
        :placeholder="$t('placeholder.title')"
        :disabled="true"
      />

      <OurInput
        v-model="form.taxNumber"
        :label="$t('label.taxNumber')"
        :placeholder="$t('placeholder.taxNumber')"
        :disabled="true"
      />
    </OurGroup>
    <OurGroup :title="$t('newApplications.dealerDetails')" :loading="!!!companies.length">
      <OurInput
        v-model="form.name"
        :label="$t('label.title')"
        :placeholder="$t('placeholder.title')"
        :error="titleError"
      />

      <OurMultiselect
        v-if="companies.length"
        v-model="form.companies"
        :options="companies"
        :label="$t('label.company') + ' *'"
        :placeholder="$t('placeholder.selectCompany')"
        item-label="label"
        :error="companiesError"
        :reload="true"
        @reloadData="reload"
      />

      <p v-if="companies.length" class="text-sm text-gray-500">
        * Перша вибрана компанія буде компанією за замовчуванням
      </p>

      <OurSelect
        v-model="form.accessGroupId"
        :options="accessGroups"
        :label="$t('label.accessGroup')"
        :placeholder="$t('placeholder.chooseFromList')"
        :error="accessGroupError"
      />

      <OurSelect
        v-model="form.storageId"
        :options="storages"
        :label="$t('label.storage')"
        :placeholder="$t('placeholder.selectStorage')"
        item-label="name"
        :error="storageError"
      />
    </OurGroup>
    <OurGroup :title="$t('newApplications.userDetails')">
      <OurInput
        v-model="form.lastName"
        :label="$t('label.lastName')"
        :placeholder="$t('placeholder.lastName')"
        :disabled="true"
      />

      <OurInput
        v-model="form.firstName"
        :label="$t('label.name')"
        :placeholder="$t('placeholder.name')"
        :disabled="true"
      />

      <OurSelect
        v-model="form.roleId"
        :options="roles"
        :label="$t('label.role')"
        :placeholder="$t('placeholder.selectRole')"
        :error="roleError"
      />

      <OurInput
        v-model="form.email"
        data-cy="email-input"
        :label="$t('label.email')"
        :placeholder="$t('placeholder.email')"
        :disabled="true"
      />

      <OurPhoneInput
        v-model="form.phone"
        :label="$t('label.phoneNumber')"
        :placeholder="$t('placeholder.phone')"
        :flags="false"
        dropdown-disabled
        :disabled="true"
      />

      <OurTextarea
        v-model="form.comment"
        :label="$t('label.comment')"
        :placeholder="$t('placeholder.comment')"
        rows="4"
      />
    </OurGroup>
  </OurGroups>
</template>

<script>
import ValidationService from "@/services/validation.service";
import { email, maxLength, minLength, numeric, required } from "vuelidate/lib/validators";
import { mapGetters, mapState } from "vuex";

const { TAX_NUMBER_MAX_LENGTH, TAX_NUMBER_MIN_LENGTH, TEXT_MAX_LENGTH } = ValidationService;

export default {
  name: "ApplicationsForm",

  props: {
    value: {
      type: Object,
      default: () => {},
    },

    editForm: {
      type: Boolean,
      default: false,
    },

    userDeleted: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        companyName: "",
        name: "",
        taxNumber: "",
        lastName: "",
        firstName: "",
        roleId: "",
        email: "",
        phone: "",
        comment: "",
        storageId: "",
        accessGroupId: "",
        companies: [],
      },
    };
  },

  validations: {
    form: {
      name: { required },
      taxNumber: {
        required,
        numeric,
        minLength: minLength(TAX_NUMBER_MIN_LENGTH),
        maxLength: maxLength(TAX_NUMBER_MAX_LENGTH),
      },
      firstName: { required, maxLength: maxLength(TEXT_MAX_LENGTH) },
      lastName: { required, maxLength: maxLength(TEXT_MAX_LENGTH) },
      email: { required, email },
      roleId: { required },
      storageId: { required },
      accessGroupId: { required },
      companies: { required },
    },
  },

  computed: {
    ...mapState("dealerUsers", ["roles", "companies"]),
    ...mapState("dealerCompany", ["accessGroups"]),
    ...mapState("accessGroups", ["storages"]),
    ...mapGetters("user", ["isUserDealerAdmin", "isUserDealer"]),

    titleError() {
      const isDirty = this.$v.form.name.$dirty;
      const isFilled = this.$v.form.name.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },

    roleError() {
      const isDirty = this.$v.form.roleId.$dirty;
      const isFilled = this.$v.form.roleId.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },

    storageError() {
      const isDirty = this.$v.form.storageId.$dirty;
      const isFilled = this.$v.form.storageId.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },

    accessGroupError() {
      const isDirty = this.$v.form.accessGroupId.$dirty;
      const isFilled = this.$v.form.accessGroupId.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },

    companiesError() {
      const isDirty = this.$v.form.companies.$dirty;
      const isFilled = this.$v.form.companies.required;

      let error = "";

      if (isDirty && !isFilled) {
        error = this.$t("validation.required");
      }

      return error;
    },
  },

  watch: {
    value: {
      handler: "getForm",
      deep: true,
    },
  },

  mounted() {
    this.getForm();
  },

  methods: {
    getForm() {
      if (this.value) {
        this.form = { ...this.value };
      }
    },

    reload() {
      this.$emit("reload");
    },

    emitForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();

        this.$nextTick(() => {
          const error = document.querySelector(".error");

          if (error) {
            error.scrollIntoView({ behavior: "instant", block: "end" });
          } else {
            const errorInput = document.querySelector(".error-input");

            errorInput.scrollIntoView({ behavior: "instant", block: "end" });
          }
        });

        return;
      }

      this.form.isValid = true;
      this.$emit("input", this.form);
    },

    emitSaveForm() {
      this.$emit("input", this.form);
    },
  },
};
</script>
