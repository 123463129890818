<script>
import { mapGetters, mapState } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import FilterModal from "../filter/Modal";
import SettingsModal from "@/views/settings/new-applications/settings/Modal.vue";
import ApplicationsList from "@/views/settings/new-applications/index/_components/ApplicationsList.vue";
import { customerApplicationsQuery } from "@/views/settings/new-applications/index/gql/queries";

export default {
  name: "NewApplications",

  components: { ApplicationsList, FilterModal, SettingsModal },

  mixins: [apolloMixin],

  data() {
    return {
      applications: [],
      paginationData: {},
      isShownFilterModal: false,
      isShownSettingsModal: false,
      sorting: { column: "updatedAt", direction: "desc" },
    };
  },

  computed: {
    ...mapState("newApplications", ["filtersForm"]),
    ...mapGetters("breakpoint", ["isMobileDevice"]),
    ...mapGetters("user", ["userProfile", "isUserDealer", "getPermission"]),
    ...mapGetters("newApplications", ["getStatus", "getFiltersOptions"]),

    icons: () => ({
      Settings: require("@material-symbols/svg-400/outlined/settings.svg"),
      FilterAltFill: require("@material-symbols/svg-400/outlined/filter_alt-fill.svg"),
    }),
  },

  watch: {
    filtersForm: {
      handler: "onChangeFiltersForm",
      deep: true,
    },
  },

  async created() {
    await this.getData();
  },

  methods: {
    async getData(page) {
      if (page && page === this.paginationData.currentPage) return;

      const filtersOptions = this.getFiltersOptions();

      const { customerApplications } = await this.$get(customerApplicationsQuery, {
        sortingOptions: this.sorting,
        page,
        filtersOptions,
      });

      this.applications = customerApplications.data.map((application) => {
        const { id, companyName, taxNumber, user, comment, status } = application;

        const { firstName, lastName, roles, phone, email } = user;

        return {
          id,
          companyTitle: companyName,
          taxNumber,
          user: {
            primaryRow: `${lastName} ${firstName}`,
            secondaryRow: roles[0]?.name,
          },
          contacts: {
            phone,
            email,
          },
          comment,
          status: this.getStatus(status),
          page: "NewApplicationsDetails",
        };
      });

      this.paginationData = customerApplications.paginatorInfo;
    },

    onClickSettingsButton() {
      this.isShownSettingsModal = true;
    },

    onClickFilterButton() {
      this.isShownFilterModal = true;
    },

    onChangeFiltersForm() {
      this.getData();
    },

    onClickRow(id) {
      this.$router.push({ name: "NewApplicationsDetails", params: { id } });
    },
  },
};
</script>

<template>
  <OurPage data-cy="users-page" :title="$t('title.newApplications._')" gray class="users-page">
    <ApplicationsList
      :items="applications"
      :pagination-data="paginationData"
      @pageChange="getData"
      @onClickRow="onClickRow"
    />

    <FilterModal :show.sync="isShownFilterModal" />

    <SettingsModal :show.sync="isShownSettingsModal" />

    <template #header-right>
      <div class="buttons">
        <div v-if="getPermission['customer.notifications']">
          <OurButton
            v-if="!isMobileDevice"
            :text="$t('title.fleaMarket.settingsModal')"
            @click="onClickSettingsButton"
          />
          <OurButton v-else @click="onClickSettingsButton">
            <OurSvgIcon
              class="settings-icon"
              :src="icons.Settings"
              color="white"
              variant="light"
              size="md"
            />
          </OurButton>
        </div>
        <OurButton
          variant="thirdary"
          filled
          :text="$t('button.filters')"
          @click="onClickFilterButton"
        >
          <OurSvgIcon v-if="isMobileDevice" :src="icons.FilterAltFill" />
        </OurButton>
      </div>
    </template>
  </OurPage>
</template>

<style lang="postcss" scoped>
.users-page {
  .buttons {
    @apply flex flex-row-reverse items-center justify-between gap-4 sm:flex-row sm:justify-start;
  }
}
</style>
