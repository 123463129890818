<template>
  <div class="users-list">
    <template v-if="isMobileDevice">
      <div v-if="items.length">
        <NewsCard
          v-for="(item, index) of items"
          :key="index"
          :item="item"
          @clickOnOrder="onClickRow({ id: item.id })"
        />
      </div>
      <div v-else>{{ $t("page.dashboard.emptyMessage") }}</div>
    </template>

    <OurTable
      v-else
      :headers="tableHeaders"
      :items="items"
      :table-fixed="false"
      class="users-list-table"
      @clickRow="onClickRow"
    >
      <template #cell-status="{ value }">
        <OurTag size="md" :text="value?.text" :color="value?.color" />
      </template>
    </OurTable>

    <OurPagination
      v-if="isMoreThanOnePage"
      :current-page="paginationData.currentPage"
      :per-page="paginationData.perPage"
      :total="paginationData.total"
      @pageChange="onPageChange"
    />
  </div>
</template>

<script>
import NewsCard from "@/views/settings/news/index/_components/NewsCard.vue";
import { mapGetters } from "vuex";

export default {
  name: "NewsList",
  components: { NewsCard },

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    paginationData: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters("breakpoint", ["isMobileDevice"]),
    tableHeaders() {
      return [
        {
          value: "name",
          text: this.$t("page.customers.title"),
          thClass: "w-[14rem]",
          tdClass: "max-w-[14rem] min-w-[10rem]",
        },
        {
          value: "status",
          text: this.$t("page.dealersCompany.status"),
          thClass: "w-[10rem]",
          tdClass: "max-w-[12rem] min-w-[10rem]",
        },
        {
          value: "publishedAt",
          text: this.$t("page.mutualSettlementsList.date"),
          thClass: "w-[10rem]",
          tdClass: "max-w-[12rem] min-w-[10rem]",
        },
      ];
    },

    isMoreThanOnePage() {
      return this.paginationData.lastPage > 1;
    },
  },

  methods: {
    onPageChange(page) {
      this.$emit("pageChange", page);
    },

    onClickRow(row) {
      this.$emit("onClickRow", row.id);
    },
  },
};
</script>
