import gql from "graphql-tag";

export const newsQuery = gql`
  query news($newsId: ID!) {
    news(id: $newsId) {
      id
      name
      isActive
      description
      content
      previewImage {
        id
        name
        link
      }
      mainImage {
        id
        name
        link
      }
      banner {
        id
        name
        link
      }
      showBanner
      publishedAt
    }
  }
`;
