import gql from "graphql-tag";

export const updateCustomerApplicationMutation = gql`
  mutation updateCustomerApplication(
    $id: ID!
    $comment: String
    $status: CustomerApplicationStatus
  ) {
    updateCustomerApplication(id: $id, comment: $comment, status: $status) {
      id
    }
  }
`;
