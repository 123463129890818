<script>
import { mapGetters, mapMutations } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { customerApplicationQuery } from "./gql/queries";
import { updateCustomerApplicationMutation } from "./gql/mutations";
import ApplicationsForm from "@/views/settings/new-applications/_components/ApplicationsForm.vue";
import { accessGroupsQuery } from "@/views/settings/dealer-company/add/gql/queries";
import { storagesQuery } from "@/views/settings/customers/add/gql/queries";
import { rolesQuery } from "@/views/settings/dealer-users/add/gql/queries";
import { createCustomerMutation } from "@/views/settings/customers/add/gql/mutations";
import { updateDealerMutation } from "@/views/settings/dealer-users/edit/gql/mutations";
import { updateCustomerMutation } from "@/views/settings/customers/edit/gql/mutations";
import { getCompanies } from "@/api/companies";

export default {
  name: "DealerUsersEdit",

  components: {
    ApplicationsForm,
  },

  mixins: [apolloMixin],

  data() {
    return {
      isShownDeleteModal: false,
      isShownRestoreModal: false,
      backRoute: {
        name: "NewApplications",
        title: this.$t("title.newApplications._"),
      },
      customer: "",
      customerId: "",
      companies: [],
      form: {
        companyName: "",
        name: "",
        taxNumber: "",
        lastName: "",
        firstName: "",
        roleId: "",
        email: "",
        phone: "",
        comment: "",
        storageId: "",
        accessGroupId: "",
        companies: [],
      },
    };
  },

  computed: {
    ...mapGetters("user", ["userProfile", "isUserDealer", "getPermission"]),
    ...mapGetters("dealerUsers", ["getStatus"]),

    fullName() {
      const { firstName, lastName } = this.form;

      return `${lastName} ${firstName}`;
    },

    havePermissionEdit() {
      return this.isUserDealer
        ? this.getPermission["dealer.update_own"]
        : this.getPermission["dealer.update"];
    },

    havePermissionDelete() {
      return this.isUserDealer
        ? this.getPermission["dealer.delete_own"]
        : this.getPermission["dealer.delete"];
    },
  },

  async created() {
    await this.getData();
    this.getAccessGroups();
    this.getStorages();
    this.getRoles();
  },

  methods: {
    ...mapMutations("dealerUsers", ["SET_ROLES", "SET_CUSTOMERS"]),
    ...mapMutations("dealerCompany", ["SET_ACCESS_GROUPS"]),
    ...mapMutations("dealerUsers", ["SET_COMPANIES"]),
    ...mapMutations("accessGroups", ["SET_STORAGES"]),

    async getData() {
      const { customerApplication } = await this.$get(customerApplicationQuery, {
        id: this.$route.params.id,
      });

      const allCompanies = await getCompanies("without");

      if (customerApplication) {
        const { companyName, taxNumber, comment, user } = customerApplication;

        const { firstName, lastName, phone, email, roles, id, detail } = user;

        const { name, accessGroup, storage, companies, id: customerId } = detail.customer || {};

        this.customerId = customerId;

        if (companies) {
          this.companies =
            companies.map((company) => {
              company.id = parseInt(company.id);

              return company;
            }) || [];
        }

        this.form = {
          companyName,
          name: name ? name : companyName,
          taxNumber,
          comment,
          firstName,
          lastName,
          phone,
          email,
          userId: id,
          roleId: roles[0]?.id,
          accessGroupId: accessGroup?.id,
          storageId: storage?.id,
          companies: companies ? companies.map((company) => parseInt(company.id)) : [],
        };

        this.SET_COMPANIES([...allCompanies.data, ...this.companies]);
      }
    },

    async getAccessGroups() {
      const { accessGroups } = await this.$get(accessGroupsQuery);

      if (!this.form.accessGroupId) {
        this.form.accessGroupId = accessGroups[0].id;
      }

      this.SET_ACCESS_GROUPS(accessGroups);
    },

    async getStorages() {
      const { storages } = await this.$get(storagesQuery);

      this.SET_STORAGES(storages);
    },

    async getRoles() {
      const { roles } = await this.$get(rolesQuery);

      this.SET_ROLES(roles);
    },

    async onApproveButton(status) {
      this.$refs.userForm.emitForm();

      status === "approved" ? (this.form.isActive = true) : (this.form.isActive = false);

      if (this.form.isValid) {
        if (this.customerId) {
          const { updateCustomer } = await this.$post(
            updateCustomerMutation,
            { id: this.customerId, ...this.form },
            {
              delaySuccessNotify: true,
            }
          );

          this.customer = updateCustomer;
        } else {
          const { createCustomer } = await this.$post(createCustomerMutation, this.form, {
            delaySuccessNotify: true,
          });

          this.customer = createCustomer;
        }

        const { updateDealer } = await this.$post(
          updateDealerMutation,
          { ...this.form, customerId: this.customer.id },
          {
            delaySuccessNotify: true,
          }
        );

        const { updateCustomerApplication } = await this.$post(
          updateCustomerApplicationMutation,
          {
            id: this.$route.params.id,
            status: status,
            comment: this.form.comment,
          },
          {
            delaySuccessNotify: true,
          }
        );

        if (updateCustomerApplication.id && this.customer.id && updateDealer.id) this.goToList();
      } else {
        const error = document.querySelector(".error-input");

        error.scrollIntoView({ behavior: "instant", block: "end" });
      }
    },

    async onSaveButton(status) {
      this.$refs.userForm.emitSaveForm();

      this.form.isActive = false;

      if (this.customerId) {
        const { updateCustomer } = await this.$post(
          updateCustomerMutation,
          { id: this.customerId, ...this.form },
          {
            delaySuccessNotify: true,
          }
        );

        this.customer = updateCustomer;
      } else {
        const { createCustomer } = await this.$post(createCustomerMutation, this.form, {
          delaySuccessNotify: true,
        });

        this.customer = createCustomer;
      }

      const { updateDealer } = await this.$post(
        updateDealerMutation,
        { ...this.form, customerId: this.customer.id },
        {
          delaySuccessNotify: true,
        }
      );

      const { updateCustomerApplication } = await this.$post(
        updateCustomerApplicationMutation,
        {
          id: this.$route.params.id,
          status: status,
          comment: this.form.comment,
        },
        {
          delaySuccessNotify: true,
        }
      );

      if (updateCustomerApplication.id && this.customer.id && updateDealer.id) this.goToList();
    },

    async onRejected(status) {
      const { updateCustomerApplication } = await this.$post(
        updateCustomerApplicationMutation,
        {
          id: this.$route.params.id,
          status: status,
          comment: this.form.comment,
        },
        {
          delaySuccessNotify: true,
        }
      );

      if (updateCustomerApplication.id) this.goToList();
    },

    goToList() {
      this.$router.push({ name: "NewApplications" });
    },
  },
};
</script>

<template>
  <OurPage
    class="users-add"
    :title="$t('title.newApplications.details')"
    :back-route="backRoute"
    width="md"
  >
    <ApplicationsForm ref="userForm" v-model="form" class="user-form" edit-form @reload="getData" />

    <template #footer-left>
      <OurButton class="button" :text="$t('button.confirm')" @click="onApproveButton('approved')" />
      <!--      <OurButton-->
      <!--        v-if="havePermissionEdit"-->
      <!--        class="button"-->
      <!--        :text="$t('button.save')"-->
      <!--        @click="onSaveButton('pending')"-->
      <!--      />-->

      <OurLink :route="backRoute">
        <OurButton class="button" :text="$t('button.back')" variant="secondary" />
      </OurLink>
    </template>

    <template #footer-right>
      <OurButton
        v-if="havePermissionDelete"
        class="button"
        variant="thirdary"
        :text="$t('button.reject')"
        @click="onRejected('rejected')"
      />
    </template>
  </OurPage>
</template>

<style lang="postcss" scoped>
.users-add {
  @apply relative;

  .user-form {
    @apply mb-6;
  }
}
</style>
