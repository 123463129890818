<template>
  <OurCard class="notice-card" border-radius="sm">
    <div class="notice-card-wrapper" @click="onClick">
      <div class="flex w-full items-center justify-between">
        <OurTag v-if="isOwn" class="status" size="md" :text="status?.text" :color="status?.color" />
        <OurTag
          v-if="isOwn"
          class="status"
          size="md"
          :text="moderationStatus?.text"
          :color="moderationStatus?.color"
        />
      </div>

      <div class="image-name-info">
        <div>
          <div class="image-wrapper">
            <img alt="img" :src="image" @error="errorImageHandler" />
          </div>
        </div>

        <div class="name">{{ name }}</div>
      </div>

      <div class="information">
        <div class="information-block">
          <OurTextField :label="$t('page.fleaMarket.pricePiece')">
            <OurMoney
              class="price"
              align="left"
              weight="bold"
              :sum="price?.sum"
              :currency-symbol="price?.currencySymbol"
            />
          </OurTextField>

          <OurTextField :label="$t('page.fleaMarket.quantity')" :value="quantity" />
        </div>

        <div class="information-block">
          <OurTextField :label="$t('page.fleaMarket.priceGeneral')">
            <OurMoney
              class="price"
              align="left"
              weight="bold"
              :sum="priceGeneral?.sum"
              :currency-symbol="priceGeneral?.currencySymbol"
            />
          </OurTextField>

          <OurTextField v-if="location" :label="$t('label.city')" :value="location" />
        </div>
      </div>

      <OurTextField
        v-if="description"
        class="description"
        :label="$t('page.cart.description')"
        :value="description"
      />
    </div>
  </OurCard>
</template>

<script>
export default {
  name: "NoticeCard",

  props: {
    isOwn: {
      type: Boolean,
      default: false,
    },

    id: {
      type: String,
      default: "",
    },

    status: {
      type: Object,
      default: () => {},
    },

    moderationStatus: {
      type: Object,
      default: () => {},
    },

    image: {
      type: String,
      default: "",
    },

    name: {
      type: String,
      default: "",
    },

    price: {
      type: Object,
      default: () => {},
    },

    priceGeneral: {
      type: Object,
      default: () => {},
    },

    quantity: {
      type: String,
      default: "",
    },

    location: {
      type: String,
      default: "",
    },

    description: {
      type: String,
      default: "",
    },
    selectedTab: {
      type: String,
      default: "",
    },
  },

  methods: {
    onClick() {
      if (this.$route.name === "NewNotices") {
        this.$router.push({
          name: "NewNoticesDetails",
          params: { id: this.id, tab: this.selectedTab },
        });
      } else {
        this.$router.push({
          name: "FleaMarketDetails",
          params: { id: this.id, tab: this.selectedTab },
        });
      }
    },
    errorImageHandler(event) {
      event.target.src = require("@/components/_bit/IconSvg/icons/Image.svg");
    },
  },
};
</script>

<style lang="postcss" scoped>
.notice-card {
  .notice-card-wrapper:deep {
    @apply py-2;
    @apply flex flex-col items-start space-y-4;

    .status {
      @apply w-auto;
    }

    .image-name-info {
      @apply flex items-start space-x-4;

      .image-wrapper {
        @apply h-[3.75rem] w-[3.75rem];
        @apply p-1;
        @apply flex justify-center;
        @apply rounded border border-solid border-gray-100;

        img {
          @apply object-contain;
        }
      }

      .name {
        @apply text-sm font-medium;
      }
    }

    .information {
      @apply flex space-x-8;

      .information-block {
        @apply flex flex-col space-y-3;
      }
    }

    .price:deep {
      .sum {
        .penny {
          @apply text-base;
        }

        .currency-symbol {
          @apply text-base;
        }
      }
    }

    .description {
      @apply w-full;
    }

    .text-field {
      &-value {
        @apply truncate;
      }
    }
  }
}
</style>
