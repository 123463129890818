import gql from "graphql-tag";

export const notificationEmailsQuery = gql`
  query notificationEmails($group: NotificationEmailGroup!) {
    notificationEmails(group: $group) {
      id
      email
    }
  }
`;
