import i18n from "@/plugins/vue-i18n";

function isProduction() {
  return process.env.VUE_APP_FEATURE_FLAG_LEGACY === "1";
}

function isUserDealer() {
  return !!Number(localStorage.getItem("isUserDealer"));
}

const icons = {
  SettingsFill: require("@material-symbols/svg-400/outlined/settings-fill.svg"),
  StarFill: require("@material-symbols/svg-400/outlined/star-fill.svg"),
  HeadsetMicFill: require("@material-symbols/svg-400/outlined/headset_mic-fill.svg"),
  ErrorFill: require("@material-symbols/svg-400/outlined/error-fill.svg"),
  ChatFill: require("@material-symbols/svg-400/outlined/chat-fill.svg"),
  Diversity1Fill: require("@material-symbols/svg-400/outlined/diversity_1-fill.svg"),
  HomeFill: require("@material-symbols/svg-400/outlined/home-fill.svg"),
  ShoppingBagFill: require("@material-symbols/svg-400/outlined/shopping_bag-fill.svg"),
  PaymentsFill: require("@material-symbols/svg-400/outlined/payments-fill.svg"),
  CasesFill: require("@material-symbols/svg-400/outlined/cases-fill.svg"),
  AutoStoriesFill: require("@material-symbols/svg-400/outlined/auto_stories-fill.svg"),
  PublicFill: require("@material-symbols/svg-400/outlined/public-fill.svg"),
};

export default {
  userBlock: {
    profileRouteName: "Profile",
    logoutAction: "logout",
    userNameGetter: "user/userProfileName",
    userEmailGetter: "user/userProfileEmail",
  },
  theme: {
    default: "bg1.jpg",
    items: ["bg1.jpg", "bg2.jpg", "bg3.jpg", "bg4.jpg", "bg5.jpg", "bg6.jpg", "bg7.jpg", "bg8.jpg"],
  },
  isProduction: isProduction(),
  redirectLink: "https://my.atmo.pro",
  settingsRoute: {
    svgIconSrc: icons.SettingsFill,
    page: "Customers",
    translate: "title.settings",
    pages: [
      {
        page: "Customers",
        permission: "customer.read",
      },
      {
        page: "DealerCompany",
        permission: "company.read",
      },
      {
        page: "DealerUsers",
        permission: isUserDealer() ? "dealer.read_own" : "dealer.read",
      },
      {
        page: "Users",
        permission: "employee.read",
      },
      {
        page: "Roles",
        permission: "role.read",
      },
      {
        page: "BaseKnowledge",
        permission: "knowledge_base.read",
      },
      {
        page: "AccessGroups",
        permission: "access_group.read",
      },
      {
        page: "NewNotices",
        permission: "notice.read",
      },
      {
        page: "News",
        permission: "news.read",
      },
      {
        page: "NewApplications",
        permission: "customer.applications",
      },
    ],
    permissions: isUserDealer()
      ? ["dealer.read_own"]
      : [
          "customer.read",
          "company.read",
          "dealer.read",
          "employee.read",
          "role.read",
          "knowledge_base.read",
          "access_group.read",
          "notice.read",
          "news.read",
          "customer.applications",
        ],
    subItems: [
      {
        translate: "title.settings",
      },
      {
        page: "Customers",
        translate: "title.customers._",
        permissions: ["customer.read"],
      },
      {
        page: "DealerCompany",
        translate: "title.dealersCompany._",
        permissions: ["company.read"],
      },
      {
        page: "DealerUsers",
        translate: "title.users.dealers",
        permissions: isUserDealer() ? ["dealer.read_own"] : ["dealer.read"],
      },
      {
        page: "Users",
        translate: "title.users._",
        permissions: ["employee.read"],
      },
      {
        page: "Roles",
        translate: "title.roles._",
        permissions: ["role.read"],
      },
      {
        page: "BaseKnowledge",
        translate: "title.knowledgeBase._",
        isHidden: isUserDealer(),
        permissions: ["knowledge_base.read"],
      },
      {
        page: "AccessGroups",
        translate: "title.accessGroups._",
        permissions: ["access_group.read"],
      },
      {
        page: "NewNotices",
        translate: "title.newNotices._",
        isHidden: isUserDealer(),
        permissions: ["notice.read"],
      },
      {
        page: "SettingsNews",
        translate: "title.news._",
        isHidden: isUserDealer(),
        permissions: ["news.read"],
      },
      {
        page: "NewApplications",
        translate: "title.newApplications._",
        isHidden: isUserDealer(),
        permissions: ["customer.applications"],
      },
    ],
  },
  newsRoute: {
    svgIconSrc: icons.StarFill,
    page: "Dashboard",
    translate: "title.whatNew",
    isHidden: true,
  },
  brandBlock: {
    name: "Atmosfeta MP",
    isHidden: true,
  },
  asideInfoBlock: [
    {
      relatedPage: "*",
      translate: {
        title: "asideInfoBlock.title",
        content: "asideInfoBlock.content",
      },
      link: {
        isButton: true,
        url: "https://my.atmo.pro",
        text: i18n.t("asideInfoBlock.backToOldCabinet"),
      },
      isHidden: !isProduction(),
    },
  ],
  rightInfoBlock: [
    {
      svgIconSrc: icons.HeadsetMicFill,
      relatedPage: "ServiceCases",
      translate: {
        title: "page.serviceCases.support.title",
        content: "page.serviceCases.support.content",
      },
      infoBlockWidth: "lg",
    },
  ],
  helperBlock: {
    isHidden: isProduction(),
    items: [
      {
        svgIconSrc: icons.StarFill,
        translate: "title.whatNew",
        link: {
          url: "https://google.com",
          targetBlank: true,
        },
      },
      {
        svgIconSrc: icons.ErrorFill,
        page: "Products",
        translate: "title.market",
        isHidden: true,
      },
      {
        isDivider: false,
      },
      {
        svgIconSrc: icons.ChatFill,
        page: "Dashboard",
        translate: "title.feedback",
        isHidden: true,
      },
      {
        svgIconSrc: icons.Diversity1Fill,
        page: "Dashboard",
        translate: "title.community",
        isHidden: true,
      },
    ],
    mobileItems: [
      {
        svgIconSrc: icons.ChatFill,
        page: "Dashboard",
        translate: "title.feedback",
        isHidden: true,
      },
      {
        svgIconSrc: icons.Diversity1Fill,
        page: "Dashboard",
        translate: "title.community",
        isHidden: true,
      },
    ],
  },
  mainMenuItems: [
    {
      svgIconSrc: icons.HomeFill,
      page: "Dashboard",
      translate: "title.dashboard._",
      isHidden: isProduction(),
      isShownOnMobileDock: !isProduction(),
    },
    {
      svgIconSrc: icons.ShoppingBagFill,
      page: "Products",
      translate: "title.catalog._",
      isHidden: isProduction(),
      isShownOnMobileDock: !isProduction(),
      permissions: isUserDealer()
        ? [
            "catalog.categories",
            "catalog.products",
            "catalog.storages",
            "catalog.price_lists",
            "catalog.dealer_price",
          ]
        : ["catalog.categories", "catalog.products", "catalog.storages", "catalog.price_lists"],
      subItems: [
        {
          translate: "sections.catalog",
        },
        {
          page: "Products",
          translate: "title.catalog._",
        },
        {
          page: "Market",
          translate: "title.market",
          isHidden: true,
        },
        {
          page: "PriceLists",
          translate: "title.priceLists",
          permissions: ["catalog.price_lists"],
        },
        {
          page: "WishList",
          translate: "title.wishList._",
          isHidden: !isUserDealer(),
        },
      ],
    },
    {
      svgIconSrc: icons.PaymentsFill,
      page: "Orders",
      translate: "title.orders._",
      permissions: isUserDealer() ? ["order.read_own"] : ["order.read"],
      subItems: [
        {
          translate: "sections.orders",
        },
        {
          page: "Orders",
          translate: "title.orders._",
          permissions: isUserDealer() ? ["order.read_own"] : ["order.read"],
        },
        {
          page: "MutualSettlements",
          translate: "title.mutualSettlements",
          permissions: isUserDealer() ? ["order.transactions_own"] : ["order.transactions"],
        },
      ],
    },
    {
      svgIconSrc: icons.CasesFill,
      page: "FleaMarket",
      pages: [
        {
          page: "FleaMarket",
          permission: "notice.read",
        },
        {
          page: "Leads",
          permission: isUserDealer() ? "lead.read_own" : "lead.read",
        },
        {
          page: "Reserved",
          permission: isUserDealer() ? "reserved_lead.read_own" : "reserved_lead.read",
        },
        {
          page: "ServiceCases",
          permission: isUserDealer() ? "ticket.read_own" : "ticket.read",
        },
      ],
      translate: "title.services",
      isShownOnMobileDock: true,
      permissions: isUserDealer()
        ? ["ticket.read_own", "notice.read", "lead.read_own", "reserved_lead.read_own"]
        : ["notice.read", "lead.read", "reserved_lead.read", "ticket.read"],
      subItems: [
        {
          translate: "sections.services",
        },
        {
          page: "FleaMarket",
          translate: "title.fleaMarket._",
          permissions: ["notice.read"],
        },
        {
          page: "Leads",
          translate: "title.leads._",
          permissions: isUserDealer() ? ["lead.read_own"] : ["lead.read"],
        },
        {
          page: "Reserved",
          translate: "title.reserved._",
          permissions: isUserDealer() ? ["reserved_lead.read_own"] : ["reserved_lead.read"],
        },
        {
          page: "ServiceCases",
          translate: "title.serviceCases._",
          permissions: isUserDealer() ? ["ticket.read_own"] : ["ticket.read"],
        },
      ],
    },
    {
      svgIconSrc: icons.AutoStoriesFill,
      page: "KnowledgeBase",
      translate: "title.knowledgeBase.menu",
      isHidden: isProduction(),
      permissions: ["knowledge_base.read"],
    },
    {
      svgIconSrc: icons.PublicFill,
      page: "News",
      translate: "title.news._",
      isHidden: isProduction(),
      permissions: ["news.read"],
    },
  ],
};
