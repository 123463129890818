import gql from "graphql-tag";

export const updateNewsMutation = gql`
  mutation updateNews(
    $id: ID!
    $name: String!
    $isActive: Boolean
    $description: String
    $content: String
    $files: SyncAssociations
    $showBanner: Boolean
    $publishedAt: Timestamp!
  ) {
    updateNews(
      id: $id
      name: $name
      description: $description
      content: $content
      isActive: $isActive
      files: $files
      showBanner: $showBanner
      publishedAt: $publishedAt
    ) {
      id
    }
  }
`;

export const deleteNewsMutation = gql`
  mutation deleteNews($userId: ID!) {
    deleteNews(id: $userId) {
      id
    }
  }
`;
