<template>
  <OurDropdownButton :text="buttonLabel" variant="thirdary" filled class="categories-dropdown">
    <div v-for="(categories, groupIndex) in groups" :key="groupIndex" class="group">
      <div
        v-for="(category, index) in categories"
        :key="index"
        class="category"
        @mouseover="onMouseOverCategory(category, index, groupIndex)"
        @click="onClickCategory(category.id)"
      >
        <div class="label">
          {{ category.description }}
        </div>
        <OurSvgIcon
          v-if="category.children.length"
          :src="icons.AngleRight"
          size="sm"
          color="gray"
        />
      </div>
    </div>
  </OurDropdownButton>
</template>

<script>
export default {
  name: "CategoriesDropdown",

  props: {
    value: {
      type: Object,
      default: () => {},
    },

    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      groups: [],
    };
  },

  computed: {
    icons: () => ({
      AngleRight: require("@/components/_bit/IconSvg/icons/Angle-right.svg"),
    }),

    buttonLabel() {
      return this.value?.description || this.$t("button.categories");
    },
  },

  created() {
    this.setFirstGroup();
  },

  methods: {
    setFirstGroup() {
      const firstGroup = this.items.filter(({ parent }) => parent === null);

      this.groups = [firstGroup];
    },

    onMouseOverCategory(category, position, groupPosition) {
      const newGroup = category.children.map((id) => {
        return this.items.find((item) => item.id === id);
      });

      groupPosition++;

      this.$delete(this.groups, groupPosition);

      if (newGroup.length) {
        this.$set(this.groups, groupPosition, newGroup);
      }
    },

    onClickCategory(id) {
      const selected = this.items.find((item) => item.id === id);

      this.$emit("input", selected);
    },
  },
};
</script>

<style lang="postcss" scoped>
.categories-dropdown:deep {
  .dropdown-wrapper {
    @apply truncate;
  }

  .dropdown-wrapper-list {
    @apply -left-1.5;

    div {
      @apply w-fit;
    }
  }

  .dropdown-list {
    @apply flex;
    @apply divide-x;
    @apply h-fit max-h-full;

    .group {
      @apply p-4;
      @apply divide-gray-100 hover:bg-white;

      & div {
        @apply p-0;
      }

      .category {
        @apply flex items-center justify-between;
        @apply rounded-lg;
        @apply w-80 space-x-5 px-5 py-3;
        @apply hover:bg-gray-900 hover:bg-opacity-5;

        .label {
          @apply text-sm font-medium;
        }
      }
    }
  }
}
</style>
