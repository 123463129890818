<template>
  <OurPage
    class="users-add"
    :title="$t('title.customers.edit')"
    :back-route="backRoute"
    width="md"
    :loading="loading"
  >
    <CustomerForm
      ref="customerForm"
      v-model="form"
      class="customer-form"
      :dealers="dealers"
      @openModal="show = !show"
      @openDealersModal="openDealersModal"
      @openAddDealersModal="openAddDealersModal"
    />

    <DealersModal
      :dealers="dealers"
      :show.sync="isShownDealersModal"
      @addDealer="openAddDealersModal"
    />

    <AddDealersModal :show.sync="isShownAddDealersModal" @updateDealers="getData" />

    <AddAccessGroupModal :show.sync="show" @getData="getData" />

    <template #footer-left>
      <OurButton
        v-if="getPermission['customer.update']"
        class="button"
        :text="$t('button.save')"
        @click="onClickSaveButton"
      />
      <OurLink :route="backRoute">
        <OurButton class="button" :text="$t('button.back')" dashed variant="secondary" />
      </OurLink>
    </template>
  </OurPage>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { customerQuery } from "./gql/queries";
import { updateCustomerMutation } from "./gql/mutations";
import { accessGroupsQuery } from "@/views/settings/dealer-company/add/gql/queries";
import AddAccessGroupModal from "@/views/settings/dealer-company/_components/AddAccessGroupModal.vue";
import DealersModal from "@/views/settings/dealer-company/_components/DealersModal.vue";
import AddDealersModal from "@/views/settings/dealer-company/_components/AddDealersModal.vue";
import CustomerForm from "@/views/settings/customers/_components/CustomerForm.vue";
import permissionsMixin from "@/mixins/permissions.mixin";
import { getCompanies } from "@/api/companies";

export default {
  name: "CustomersEdit",

  components: {
    CustomerForm,
    AddDealersModal,
    DealersModal,
    AddAccessGroupModal,
  },

  mixins: [apolloMixin, permissionsMixin],

  data() {
    return {
      isShownDealersModal: false,
      isShownAddDealersModal: false,
      backRoute: {
        name: "Customers",
        title: this.$t("title.customers._"),
      },
      companies: [],
      form: {
        name: "",
        storageId: "",
        accessGroupId: "",
        companies: [],
        isActive: true,
      },
      show: false,
      dealers: [],
      loading: false,
    };
  },

  computed: {
    ...mapGetters("dealerUsers", ["getStatus"]),
    ...mapGetters("user", ["getPermission"]),

    customerId() {
      return this.$route.params.id;
    },
  },

  created() {
    this.getData();
  },

  methods: {
    ...mapMutations("dealerCompany", ["SET_ACCESS_GROUPS"]),
    ...mapMutations("dealerUsers", ["SET_COMPANIES"]),

    async getData() {
      this.loading = true;

      const { accessGroups } = await this.$get(accessGroupsQuery);

      this.SET_ACCESS_GROUPS(accessGroups);

      const customerId = this.customerId;

      const allCompanies = await getCompanies("without");

      const { customer } = await this.$get(customerQuery, { customerId });

      if (customer) {
        const { name, isActive, accessGroup, storage, companies, dealers } = customer;

        this.companies =
          companies.map((company) => {
            company.id = parseInt(company.id);

            return company;
          }) || [];

        this.dealers = dealers.map((dealer) => {
          const { id, email, phone, firstName, lastName, roles, detail } = dealer;
          const { status } = detail;
          const userStatus = this.getStatus(status);
          const role = roles[0].name;

          return {
            id,
            user: {
              primaryRow: `${lastName} ${firstName}`,
              secondaryRow: role,
            },
            contacts: {
              phone,
              email,
            },
            status: userStatus,
          };
        });

        this.form = {
          name,
          accessGroupId: accessGroup?.id,
          isActive,
          storageId: storage?.id,
          companies: companies.map((company) => parseInt(company.id)),
        };
      }

      this.SET_COMPANIES([...allCompanies.data, ...this.companies]);

      this.loading = false;
    },

    async onClickSaveButton() {
      this.$refs.customerForm.emitForm();

      if (this.form.isValid) {
        const form = {
          ...this.form,
          defaultCompanyId: this.form.companies[0].id,
          id: this.$route.params.id,
        };

        const { updateCustomer } = await this.$post(updateCustomerMutation, form, {
          delaySuccessNotify: true,
        });

        if (updateCustomer.id) this.goToList();
      }
    },

    onClickDeleteButton() {
      this.isShownDeleteModal = true;
    },

    goToList() {
      this.$router.push({ name: "Customers" });
    },

    openDealersModal() {
      this.isShownDealersModal = true;
    },

    openAddDealersModal() {
      this.isShownAddDealersModal = true;
    },
  },
};
</script>
